
<template>
  <div>
    <Row :gutter="8">

      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select size="small" v-model="companyId" placeholder="公司" clearable>
          <Option
            v-for="(channel, index) in channellist"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select size="small" v-model="settlementCompanyId" placeholder="结算公司" clearable>
          <Option
            v-for="(company, index) in settlementCompanyArray"
            :key="index"
            :value="company.id"
            >{{ company.name }}</Option
          >
        </Select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <DatePicker
          placeholder="账期"
          v-model="billDate"
          size="small"
          type="month"
          style="width: 100%"
        ></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select size="small" v-model="billType" placeholder="账单类型" clearable>
          <Option
            v-for="(item, index) in billTypeArray"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <!-- <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <Select size="small" v-model="companyId" placeholder="账单状态" clearable>
          <Option
            v-for="(channel, index) in channellist"
            :key="index"
            :value="channel.id"
            >{{ channel.name }}</Option
          >
        </Select>
      </i-col> -->

      <i-col :xs="24" :sm="12" :md="6" :lg="4" class="m-b-5">
        <i-input
          placeholder="关键字"
          size="small"
          v-model="query.keywords"
        ></i-input>
      </i-col>
      <i-col
        :xs="24"
        :sm="6"
        :md="6"
        :lg="4"
        class="m-b-5"
        style="min-width: 150px"
      >
        <i-button
          class="m-r-10"
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch()"
          >搜索</i-button
        ><i-button type="success" size="small" @click="handleExportExcel"
          >导出Excel</i-button
        >
      </i-col>
    </Row>

    <!-- <Row
      class="m-t-5 p-t-5 p-b-5"
      style=" color: #44bd32; background: #3b3b3b"
    >
      <i-col span="12" style="padding: 2px 18px;color:#fff;">全部合计</i-col>
      <i-col span="3" style="padding: 2px 18px">{{
        formatMoney(groupSummary.amount)
      }}</i-col>
      <i-col span="3" style="padding: 2px 18px">{{
        formatMoney(groupSummary.receivedAmount)
      }}</i-col>
      <i-col span="3" style="padding: 2px 18px">{{
        formatMoney(groupSummary.overdueNotReceiveAmount)
      }}</i-col>
      <i-col span="3"></i-col>
    </Row> -->
    <Table stripe :data="monthBillList" :columns="tableColumns"></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="handlePageChanged"
      ></Page>
    </div>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import {
  getMonthBillList, getValidBillType, getSettlementCompanyList
} from '@/api/bill/monthBill'

import { getCompanyChild } from '@/api/os/company'
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    params: Object
  },
  data () {
    return {
      companyId: '',
      channellist: [],

      settlementCompanyId: '',
      settlementCompanyArray: [],

      billType: '',
      billTypeArray: [],

      billDate: '',
      query: {
        keywords: '',
        startDate: '',
        endDate: '',
        pageNumber: 1,
        pageSize: 15
      },
      monthBillList: [],
      total: 0,
      tableColumns: [
        {
          title: '编号',
          key: 'billCode'
        },
        {
          title: '公司',
          key: 'companyName'
        },
        {
          title: '结算公司',
          key: 'settlementCompanyName'
        },
        {
          title: '账单类型',
          key: 'billTypeName'
        },
        {
          title: '账期',
          key: 'billPeriod'
        },
        {
          title: '金额',
          key: 'amount',
          render: (h, params) => {
            return h('div', [
              h('span', { class: 'money' }, toMoney(params.row.amount))
            ])
          }
        },
        {
          title: '状态',
          key: 'billStatus',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                params.row.billStatus
              )
            ])
          }
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'a',
                {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleShowDetail(params.row)
                    }
                  }
                },
                '详情'
              )
            ])
          }
        }
      ]
    }
  },
  created () {
    getCompanyChild({
      companyId: this.$store.getters.token.userInfo.companyId,
      type:
        this.$store.getters.token.userInfo.publisherId ===
        this.$store.getters.token.userInfo.companyId
          ? 1
          : 2,
      childType: 2,
      companyType: 1
    }).then((res) => {
      let array = [
        {
          id: res.companyId,
          name: res.companyName
        }
      ]
      if (res.children.length) {
        array = array.concat(
          this.formatCompanyArray(res.children).filter(
            (x) => x.id !== res.companyId
          )
        )
      }
      this.channellist = array
    })
    getValidBillType().then(res => {
      this.billTypeArray = res
    })
    this.initPageData()
  },
  watch: {
    companyId () {
      if (this.companyId) {
        getSettlementCompanyList({ companyId: this.companyId }).then(res => {
          this.settlementCompanyArray = res
        })
      } else {
        this.settlementCompanyArray = []
        this.settlementCompanyId = ''
      }
    },
    beginUpdate (val) {
      this.initPageData()
    }
  },
  methods: {
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach((item) => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(
            this.formatCompanyArray(item.children)
          )
        }
      })
      return resultArray
    },
    formatMoney (number) {
      return toMoney(number)
    },
    handleShowDetail (item) {
      this.$store.commit('set_bill_pageType', 'detail')
      this.$store.commit('set_bill_monthBillId', item.billId)
    },
    handlePageChanged (page) {
      this.query.pageNumber = page
      this.initPageData()
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.query.startDate = this.billDate ? ParseDate(this.billDate) : ''
      this.query.endDate = this.billDate ? ParseDate(this.billDate) : ''
      this.query.companyId = this.companyId || ''
      this.query.settlementCompanyId = this.settlementCompanyId || ''
      this.initPageData()
    },
    initPageData () {
      getMonthBillList(this.query).then((res) => {
        this.monthBillList = res.list
        this.total = res.totalRow
      })
    },
    handleExportExcel () {
      const query = {
        startDate: this.billDate ? ParseDate(this.billDate) : '',
        endDate: this.billDate ? ParseDate(this.billDate) : '',
        companyId: this.companyId || '',
        ettlementCompanyId: this.settlementCompanyId || '',
        keywords: this.query.keywords || ''
      }
      // const query = Object.assign({}, this.query)
      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 +
          '/ooh-bill/bill/monthBill/exportMonthBill',
        query,
        '月账单列表.xlsx'
      )
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.bill.beginUpdate
    }
  }
}
</script>
